import React, { useEffect } from 'react';
import Head from '@components/head';
import Router from 'next/router';
var Root = function () {
    useEffect(function () {
        Router.push('/forms/:formId/end_users/:endUserId');
    }, []);
    return <Head title="Zeals Integrated Webform"/>;
};
export default Root;
